<template>

    <nav class="uk-navbar-container top-navbar-container uk-margin uk-margin-remove-bottom uk-box-shadow-medium "
         :class="{'uk-active' : isWindowScrolled}"
         style="position: fixed; top: 0; left: 0; width: 100%; z-index: 50;"

         uk-navbar>

        <div class="top-menu uk-flex uk-background-inverse uk-margin-auto">
            <div class="uk-flex  uk-width-expand">
                <div class="logo-container">
                    <slot name="logo">
                        <a class="uk-navbar-item uk-logo" @click="$router.push({name: 'selfcare-dashboard'})" :style="logoStyles"></a>
                    </slot>

                </div>
                <div class="main-menu-content  uk-width-expand">
                    <div class="client-type-bar ">
                        <div class="client-type-bar-inner">
                            <ul class="client-options">
                                <li>
                                    <a href="#" class="uk-active" @click.prevent="">
                                        Individuals
                                    </a>
                                </li>
                                <li>
                                    <a  href="#" @click.prevent="">
                                        Enterprise
                                    </a>
                                </li>
                                <li>
                                    <a href="#" @click.prevent="">
                                        Tourists
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="main-menu-inner nav-overlay uk-flex uk-width-expand ">
                        <div class="nav-overlay uk-navbar-left uk-visible@l ">
                            <ul class="main-menu-items uk-navbar-nav uk-visible@s">
                                <slot name="menuItems">
                                    <li>
                                        <a href="#">
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <router-link to="/">
                                            Shop
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/">Games</router-link>
                                    </li>
                                </slot>
                            </ul>
                        </div>
                        <div class="nav-overlay uk-navbar-left uk-hidden@l ">
                            <ul class="uk-navbar-nav">
                                <li class="mobile-menu-toggles">
                                   <span class=" uk-margin-small-right open-menu-icon"
                                        uk-toggle="target: .top-side-nav, .open-menu-icon, .close-menu-icon; animation: uk-animation-slide-left-small uk-animation-fade" >
                                        <span uk-icon="icon: menu; ratio: 1.8;"></span>
                                    </span>
                                    <span class=" uk-margin-small-right open-menu-icon" hidden
                                          uk-toggle="target: .top-side-nav, .open-menu-icon, .close-menu-icon; animation: uk-animation-slide-left-small uk-animation-fade" >
                                        <span uk-icon="icon: close; ratio: 1.8;"></span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="nav-overlay uk-navbar-right">
<!--
                            <div class="uk-navbar-item">
                                <ul class="uk-navbar-nav">
                                    <li>
                                        <span class=" uk-margin-small-right" uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="#">
                                            <span uk-icon="icon:search;ratio:1.8"></span>
                                        </span>
                                    </li>
                                </ul>
                            </div>-->



                            <div class="uk-navbar-item">


                                <ul class="uk-navbar-nav">
                                    <li>
                                        <span class="nav-cart-link" >
                                            <span class="nav-cart-icon uk-icon uk-margin-small-right"
                                                  style="cursor: pointer;"
                                                  uk-icon="icon: cart; ratio: 2"
                                                  uk-tooltip="Show Cart"
                                                  @click="$store.commit('cart/setOpen', true)"></span>
                                            <span class="nav-cart-count uk-badge">{{$store.getters['cart/itemCount']}}</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <!--
                            <div class="uk-navbar-item">
                                <ul class="uk-navbar-nav">
                                    <li>
                                        <span class="nav-lang-switcher" href="#">
                                            <language-switcher></language-switcher>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            -->
                            <div class="uk-navbar-item uk-navbar-item--user">
                                <div class="user-status-widget uk-padding-small">
                                    <div class="user-greeting uk-flex uk-flex-bottom uk-text-bold">
                                        <span uk-icon="icon:user; ratio: 1.5 " class="greeting-icon"></span> <span class="greeting-text">Hello John</span>
                                    </div>

                                    <div class="user-widget-separator"></div>
                                    <div class="user-actions uk-flex uk-flex-bottom" @click="logout()">
                                        <span uk-icon="sign-out" ></span><span>Logout</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="search-bar nav-overlay uk-navbar-left uk-flex-1" hidden>

                        <div class="uk-navbar-item uk-width-expand">
                            <form class="uk-search uk-search-navbar uk-width-1-1">
                                <input class="uk-search-input" type="search" placeholder="Search..." autofocus>
                            </form>
                        </div>

                        <a class="uk-navbar-toggle" uk-close uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="#"></a>

                    </div>
                </div>
            </div>

            <div class="top-side-nav" hidden>
                <div class="background-block"></div>
                <ul class="side-menu-items uk-navbar-nav ">

                    <slot name="menuSideItems">
                        <li>
                            <a href="#">
                                <span>Subscriptions</span>
                                <span uk-icon="chevron-right" class="uk-text-primary"></span>
                            </a>
                        </li>
                        <li>
                            <router-link to="/">
                                <span>Device catalogue</span>
                                <span uk-icon="chevron-right" class="uk-text-primary"></span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                <span>My Vodafone</span>
                                <span uk-icon="chevron-right" class="uk-text-primary"></span>

                            </router-link>
                        </li>
                    </slot>
                </ul>

                <hr class="uk-hr">
                <ul class="side-menu-items side-menu-items--extra uk-navbar-nav ">

                    <slot name="menuSideItems">
                        <li>
                            <a href="#" class="uk-active">
                                <span>Individuals</span>
                                <span class="item-icon" uk-icon="check" ></span>
                            </a>
                        </li>
                        <li>
                            <router-link to="/">
                                <span>Enterprise</span>
                                <span class="item-icon" uk-icon="chevron-right"></span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                <span>Tourists</span>
                                <span class="item-icon" uk-icon="chevron-right"></span>

                            </router-link>
                        </li>
                    </slot>
                </ul>
            </div>



        </div>

    </nav>
    <div class="spacer" style="height: 120px;"></div>
</template>

<script>
    //import LanguageSwitcher from '@/client/applications/billrunPortal/components/selfcare/widgets/LanguageSwitcher.vue';

    export default {
        props: {
            logo: {
                type: String,
                default: 'logos/main.svg'
            }
        },
        data: function () {
            return {
                scrollStick: 40,
                isWindowScrolled: false,
            };
        },
        components: {}, //LanguageSwitcher
        computed: {
            logoStyles () {
                let url = utilities.requireAsset(this.logo);
                return {
                    backgroundImage : "url('"+url+"')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: '100% auto',
                    width: '60px'
                };
            }
        },
        methods: {
            logout() {
                this.$store.commit('user/logout');
                this.$router.push({name: 'user-login'});
            },
            updateWindowScrollState() {
                if ( utilities.isSSR()) {
                    return;
                }

                this.isWindowScrolled = window.scrollY > this.scrollStick;
            },
            windowScrollHandler(e) {

                clearTimeout(this.scrollThrottle);
                this.scrollThrottle = setTimeout(this.updateWindowScrollState, 1);

            },
            bindWindowScrollHandler(){
                if ( utilities.isSSR()) {
                    return;
                }

                window.addEventListener('scroll', this.windowScrollHandler);
            },
            unbindWindowScrollHandler(){
                if ( utilities.isSSR()) {
                    return;
                }
                window.removeEventListener('scroll', this.windowScrollHandler);
            },
        },
        mounted(){
            this.bindWindowScrollHandler();
            setTimeout(this.updateWindowScrollState, 1);

        },
        beforeUnmount (){
            this.unbindWindowScrollHandler();
        },
    }
</script>

<style scoped lang="scss">
    .top-navbar-container {
        --top-menu-size: 124px;

        &.uk-active {
            --top-menu-size: 80px;
        }

        @media screen and (max-width: 600px) {
            --top-menu-size: 80px;
        }
    }

    .top-menu {
        width: 100%;
    }

    .user-status-widget {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: var(--global-link-color);
        color: var(--global-background);
        height: 80px;

        @media (max-width: 767px) {
            .user-greeting{
                display: none;
            }
            .user-widget-separator {
                display: none;
            }
        }
    }

    .greeting-icon {
        margin-right: 2px;
    }

    .greeting-text {
        position: relative;
        top: 4px;
    }

    .user-widget-separator {
        background-image: linear-gradient(to right,  var(--global-link-color) 0% ,  var(--global-background) 20%, var(--global-background) 80%, var(--global-link-color) 100%);
        flex: 0 0 1px;
        width: 100%;
        margin: 7px 0 3px;
    }

    .user-greeting, .user-actions {
        cursor: pointer;
    }

    .uk-navbar-nav li a {
        color: var(--global-color);

        &:hover {
            color: var(--global-link-color);
        }
    }

    .nav-cart-link {
        position: relative;
        cursor: pointer;

        .nav-cart-icon {

        }
        &:hover {
            color: var(--global-link-color);

            .nav-cart-icon {
                color: var(--global-link-color);
            }
        }
    }

    .nav-cart-count {
        position: absolute;
        top: -15px;
        right: 2px;
        z-index: 2;
    }

    .search-bar {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }

    .uk-navbar-item--user {
        padding-inline-end: 0;
    }

    .client-type-bar {
        --bar-height: 44px;

        display: flex;
        align-items: stretch;



        @media screen and (max-width: 600px) {
           display: none;
        }

        border-top: 1px solid transparent;
        padding: 0 calc(var(--global-margin) * 0.5);
        background-color: var(--global-secondary-background);
        overflow: hidden;
        height: var(--bar-height);
        transition:
                margin 400ms ease-in-out,
                opacity 400ms ease-in-out,
                transform 400ms ease-in-out;
        margin-top: 0;
        position: relative;

        .client-type-bar-inner {
            display: flex;
            align-items: stretch;
            transform: none;

        }


        --top-menu-size: 120px;

       .uk-active & {
            margin-top: calc(var(--bar-height) * -1);
            transform: translateY(calc(var(--bar-height) * -1));
            opacity: 0;

            .client-type-bar-inner {

            }
        }

        * {
            color: var(--global-inverse-background);
        }

        .client-options {
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;

            li {
                display: flex;

                a {
                    position: relative;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: var(--global-inverse-color);
                    padding: 5px calc(var(--global-margin) * 1.5);
                    text-decoration: none;
                    transition: background-color 200ms, color 200ms;
                    will-change: background-color, color;

                    &.uk-active, &.active, &:hover {
                        background-color: var(--global-inverse-color);
                        color: var(--global-secondary-background);
                        text-decoration: none;
                    }
                }


            }
        }

    }

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 calc(var(--global-margin) * 1.5);

        @media screen and (max-width: 450px) {
            padding: 0 calc(var(--global-margin) * 0.4);
        }
    }
    @media screen and (max-width: 450px) {
       .uk-navbar-item {
           padding: 0 calc(var(--global-margin) * 0.4);
       }
    }


    .top-side-nav {
        position: fixed;
        inset-inline-start: 0;


        top: var(--top-menu-size);
        height: calc(100vh - var(--top-menu-size));
        z-index: -1;
        min-width: 300px;
        max-width: 100vw;
        box-shadow:  0 0 13px 1px rgba(0,0,0,0.3);
        overflow-y: scroll;
        transition: top 400ms ease-in-out;

        .background-block {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: var(--global-inverse-color);
            opacity: 1;
            z-index: 0;
        }
    }

    .mobile-menu-toggles {
        span {
            cursor: pointer;
        }
    }


</style>

<style lang="scss">
    //NON SCOPED STYLE for menu item slot

    .top-menu {
        --menu-item-padding: calc(var(--global-margin) * 1);

        .main-menu-items {


            li {
                a {

                    position: relative;
                    font-size: calc(var(--global-font-size) * 1.3);
                    padding: 4px var(--menu-item-padding);
                    font-weight: 200;
                    transition: color 175ms ease-in-out;
                    cursor: pointer;

                    &::after {
                        content: ' ';
                        z-index: 2;
                        position: absolute;
                        bottom: 0;
                        height: 2px;
                        left: 0;
                        width: 0;
                        background-color: var(--global-primary-background);
                        transition: width 200ms ease-out;
                        will-change: width;

                    }
                }
            }

            li.active a,
            li.uk-active a,
            li a.active,
            li a.uk-active,
            li a:hover {
                color: var(--global-primary-background);
            }

            li a:hover {

                &::after {
                    width: 100%;
                }
            }

            .uk-dropdown-nav {
                padding: calc(var(--global-margin) * 1.1) (calc(var(--global-margin) * 1));
                li {

                    a {
                        color: var(--global-color);
                        display: block;
                        min-width: 250px;
                        padding: calc(var(--global-margin) * 0.4) 0;
                        font-size: calc(var(--global-font-size) * 1.1);
                    }
                }
            }
        }

        .side-menu-items {
            display: block;
            position: relative;
            z-index: 1;
            padding-top: calc(var(--global-margin) * 0.25);

            li {
                display: block;
                margin-top: calc(var(--global-margin) * 0.25);
                a {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    text-align: start;
                    min-height: 0;
                    height: auto;
                    padding: calc(var(--global-margin) * 0.6) calc(var(--global-margin));
                    font-size: calc(var(--global-font-size) * 1.2);
                    background-color: var(--global-muted-background);
                    color: var(--global-secondary-color);

                }
            }
        }

        .side-menu-items.side-menu-items--extra {
            li {
                a {
                    .item-icon {
                        color: var(--global-primary-background);
                    }

                    &.uk-active, &.active, &:hover {
                        background: var(--global-primary-background);
                        color: var(--global-inverse-color);

                        .item-icon {
                            color: var(--global-inverse-color);
                        }
                    }
                }
            }
        }
    }
</style>
